import tajirLogo from './tajir-logo.png';
import './App.css';
import React from 'react';
import Po from "./po";
import NotFound from "./notFound";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import ScrollToTop from 'react-scroll-to-top'

function App() {

    return (
      <>
        <div className="d-flex justify-content-center w-100" style={{minHeight: "100%"}}>
            <Router>
                <Routes>
                    <Route exact path='/po/:poId' element={<Po isTest={false} />} />
                    <Route exact path='/po/preview/:poId/' element={<Po isTest={true} />} />
                    <Route path='*' element={<NotFound />} />
              </Routes>
            </Router>
        </div>
      </>
    );
}

export default App;
