import React from "react";
import numbro from "numbro";

const PoItem = ({index, poItem}) => {

    return (<>
        <div className="table-row py-1">
            <div className="row mx-0 align-items-center justify-content-center flex-nowrap" key={index}>
                <div className="ps-0 col-8">
                    <div className="d-flex align-items-baseline">
                        <div className="px-2 serial-num">
                            <span className="text-muted">{index + 1}</span>
                        </div>
                        <div>
                            <span className="sku-name">{poItem.sku_name}</span>
                        </div>
                    </div>
                </div>
                <div className="px-1 col-4 justify-content-center align-items-center pill">
                    <div className="row p-1 mx-0 d-flex justify-content-center flex-nowrap">
                        <div className="col-5">
                            <div className="row d-flex justify-content-center align-items-baseline">
                                <span className="col-12 quantity px-0">
                                    {numbro(poItem.procurement_units).format({thousandSeparated: true})}
                                </span>
                                <span className="col-12 quantity-label text-muted px-1">
                                    {poItem.procurement_label}
                                </span>
                            </div>
                        </div>
                        <div className="col-1 equal text-muted">
                            <span>=</span>
                        </div>
                        <div className="col-5">
                           <div className="row d-flex justify-content-center align-items-baseline">
                                <span className="col-12 quantity px-0">
                                    {numbro(poItem.selling_units).format({thousandSeparated: true})}
                                </span>
                                <span className="col-12 quantity-label text-muted px-1">
                                    {poItem.selling_label}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr className="my-0"/>
    </>)
}

export default PoItem;