import React from 'react';
import tajirLogo from './tajir-logo.png';

const NotFound = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="text-center col-10 col-md-6 col-lg-4">
        <p className="text-muted fw-bold mb-5"><em>Sorry, the page you are looking for was not found.</em></p>
        <img className="img-fluid" src={tajirLogo} style={{maxHeight:"500px"}} alt="404 not found" />
      </div>
    </div>
  );
};

export default NotFound;
