import React from "react";
import axios from "axios";
import moment from "moment";
import { isValidUUIDV4 } from 'is-valid-uuid-v4';
import {useParams} from "react-router-dom";
import {useState, useEffect, useRef} from "react";
import {TailSpin} from "react-loader-spinner";
import { CSSTransition } from 'react-transition-group';
import tajirLogo from './tajir-logo.png';
import tajirHouse from './tajir-house.png';
import PoItem from "./poItem";

const Po = ({isTest}) => {
    const [poId, setPoId] = useState(useParams().poId);
    const [po, setPo] = useState(null)
    const [reported, setReported] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inProp, setInProp] = useState(false);
    const nodeRef = useRef(null);

    const samplePO = {
        code: "LHE-RHN-WHMH-17122023-B",
        status: "Completed",
        delivery_date: "2021-06-23",
        delivery_address: "Majestic Lahore, 62, 113/1B Kacha Road, Quaid e Azam Industrial Estate, Lahore, Punjab",
        delivery_location_lat: 31.5204,
        delivery_location_lng: 74.3587,
        receiving_hours_start: "2021-06-23 9:00:00",
        receiving_hours_end: "2021-06-23 18:00:00",
        payment_terms: "As per agreed terms",
        bm_name: "Muhammad Ali",
        bm_phone: "0300-1234567",
        contents: [
            {
                sku_name: "7up plastic 1.5L",
                procurement_units: 1865,
                procurement_label: "carton",
                selling_units: 1865,
                selling_label: "Crate",
            },
        ]
    }

    // we only need to set po once when the component is mounted, so we use an empty array as the second argument
    useEffect(() => {
        const api = `${process.env.REACT_APP_API_URL}/po/${poId}`;
        setLoading(true);
        if(isValidUUIDV4(poId)) {
            axios.get(api)
                .then(response => {
                    setPo(response.data.data.purchase_order);
                    setInProp(true);
                    setLoading(false);
                })
                .catch(err => {
                    setError(err);
                    setLoading(false);
                });
        } else {
            setError(true);
            setLoading(false);
        }
    }, [poId]);

    // report viewing of the PO by calling an api, with the current time (in UTC) as the parameter
    useEffect(() => {
        if(!po || isTest || reported) return;
        setReported(true);

        const api = `${process.env.REACT_APP_API_URL}/po/${poId}/event/view`;
        const data = {
            viewed_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
        }
        axios.post(api, data)
            .then(response => {
                // console.log(response);
            })
            .catch(err => {
                // console.log(err);
            });
    }
    , [po, isTest]);

    // function to print po and tell api that po has been printed
    const printPO = () => {
        let printContents = document.getElementById('po-card').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;

        // hit the api
        axios.post(`https://jsonplaceholder.typicode.com/todos/${poId}`)
            .then(response => {
                // console.log(response);
            })
            .catch(err => {
                // console.log(error);
            });

        // reload window to restore download-button functionality
        window.location.reload();
    }

    return (
        <CSSTransition
            key={poId}
            in={inProp}
            nodeRef={nodeRef}
            timeout={500}
            classNames="fade"
        >
        {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{margin: "25% 50%"}}>
                <TailSpin
                    height="50"
                    width="50"
                    color="#4caf50"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={loading}
                />
            </div>
        ) : error ? (
            <div className="row justify-content-center align-self-start align-items-center mx-2 my-4 responsive-font-3">
                <div className="col-12 alert alert-danger mx-3" role="alert"> The requested PO was not found! </div>
                <div className='col-12 text-muted fst-italic w-100'>Please contact your Tajir POC for guidance.</div>
            </div>
        ) : (
            <div className="row m-4 px-3 responsive-font-3 no-print-margin no-print-padding no-print-shadow" id="po-container" ref={nodeRef} >
                <div className="col-12" id="po-card">
                    {po && (<>
                        <div className="row mb-1">
                            <div className="col-8 col-md-6 d-flex align-items-center justify-content-start py-1">
                                <div className="pt-3">
                                    <h3 className="fw-bold">Purchase Order</h3>
                                    <h6 className="fw-bold text-muted">{po.code}</h6>
                                </div>
                            </div>
                            <div className="col-6 d-none d-md-block py-4">
                                <a href="https://www.tajir.app/sellers" target="_blank" rel="noreferrer">
                                    <img className="float-md-end" src={tajirLogo} alt="Sample" height={60} />
                                </a>
                            </div>
                             <div className="col-4 d-md-none justify-content-end d-flex align-items-center">
                                <a href="https://www.tajir.app/sellers" target="_blank" rel="noreferrer">
                                    <img className="" src={tajirHouse} alt="Tajir" height={60} />
                                </a>
                            </div>
                        </div >
                        {po.status === 'Completed' && (
                            <div className="row">
                                <div className="col-12  d-flex justify-content-end align-items-center">
                                    <p className="m-0 p-2 urdu-container urdu-container-green border w-100"> آڈر وصول ہو چکا ہے<span className="ms-2">✅ </span></p>
                                </div>
                            </div>
                        )}
                        <div className="row my-3">
                            <div className="col-12" dir="rtl">
                                <p className="m-0 p-2 urdu-container border">
                                    <p className="mb-0 d-flex"><div className="ms-2">●</div>لیٹ سپلائی وصول نہیں کی جائے گی</p>
                                    <p className="mb-0 d-flex"><div className="ms-2">●</div>ریجیکٹ کیے گئے سامان کو اگلے 7 دنوں میں واپس اٹھاليں، اس کے بعد کمپنی ذمہ دار نہیں ہوگی</p>
                                </p>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-12 col-sm-6 mt-responsive-3 no-print-break">
                                <p className="label responsive-font-2">Contact</p>
                                <h6 className="responsive-font-3 ">{po.bm_name}
                                    <a href={`tel:${po.bm_phone}`} className="text-decoration-none fw-bold" style={{color:"#2680c2"}}>
                                        {" (" + po.bm_phone + ")"}
                                    </a>
                                </h6>
                            </div>
                            <div className="col-12 col-sm-6 mt-responsive-3 no-print-break">
                                <p className="label responsive-font-2">Delivery Instructions</p>
                                <h6 className="responsive-font-3">{moment(po.delivery_date).format('dddd, D MMM YYYY')} &mdash; {moment(po.receiving_hours_start).format('ha')} to {moment(po.receiving_hours_end).format('ha')}</h6>
                                <h6 className="responsive-font-3">
                                    {po.delivery_address}
                                </h6>
                                <h6 className="responsive-font-3 no-print">
                                    <a href={`https://maps.google.com/?q=${po.delivery_location_lat},${po.delivery_location_lng}`} target="_blank" className="">
                                        View on Google Maps
                                    </a>
                                </h6>
                            </div>
                        </div>
                        <div className="row mt-responsive-4">
                            <div className="col-12">
                                <div className="row fw-bold me-0 justify-content-center align-items-end">
                                    <div className="col-8">
                                        <span className="ms-2">SKU</span>
                                    </div>
                                    <div className="col-4 text-center">
                                       Order QTY
                                    </div>
                                </div>
                                <hr className="header-line mt-0 mb-1" />
                                {po.contents.map((item, index) => (
                                    <PoItem index={index} poItem={item} key={index} />
                                ))}
                            </div>
                        </div>
                        <div className="row mt-auto justify-content-center">
                            <div className="col-6 col-sm-4">
                                <hr className="footer-line text-center my-5" />
                            </div>
                        </div>
                    </>)}
                </div>
                {/* Footer */}
                <div className="col-12 mt-auto">
                    <footer className="my-3 responsive-font-3 text-center text-muted">
                        <span className="">
                            © {new Date().getFullYear()} <a href="https://www.tajir.app/sellers" target="_blank" className="footer text-muted">Tajir</a>
                        </span>
                    </footer>
                </div>
            </div>
        )}
        </CSSTransition>
    )
}

export default Po;